<template>
    <div id="BasicCourseVideos">
        <p class="f14 f12-mb course-intro-style">
            「大自然界
            基本セミナー」は、生き方をより幸せにする、人生の基礎講習です。<br />
            老若男女の差を越えて、誰にとっても必要不可欠な大自然の真理法則を学ぶ為のセミナーです。<br />
            様々な真理法則を、単に知識として学ぶのではなく、実生活・現実の人生に、本当に役立つ悟りとして身に付くよう学んでいきます。<br />
            <br />
            「大自然界
            基本セミナー」は全6項目の講習ですが、それぞれ3回ずつ受講される事を推奨しております。<br />
            1回目：ノートなどは取らずに、内容に集中して学修します。<br />
            2回目：ノートに取って学修します。<br />
            3回目：他の項目を学修した後、実生活で実践した後、あるいは<span
                @click="scrollInToView('#basic-video-article')"
                class="text-link-style"
                >「親理総合理学修の理」</span
            >を頂いた後など、少し間をおいて改めて学修します。
        </p>
        <ul class="video-list-style">
            <li v-for="item in videoList" :key="item._id">
                <h3 class="f20 f14-mb">
                    {{ item.name }}
                    <ElIcon v-if="serverRole !== 'admin' && item.isWatched"
                        ><SuccessFilled
                    /></ElIcon>
                </h3>
                <video
                    :poster="item.thumbnailUrl"
                    controls
                    controlsList="nodownload"
                    @play="pauseAll()"
                    @ended="watchCourse(item)"
                >
                    <source
                        :src="isPC ? item.video1280Url : item.video720Url"
                        type="video/mp4"
                    />
                </video>
            </li>
        </ul>
        <article id="basic-video-article">
            <h3 class="f16 f14-mb bold">【親理総合理学修の理】</h3>
            <p class="f14 f12-mb">
                「大自然界
                基本セミナー」は、生命本来の新しい生き方を悟る為の講習です。
                <br />その為には、「大自然界」の真理法則を基準にした、新しい意識や考え方を開発発展する必要があります。
                <br />「親理総合理学修の理」は、お授け頂いた内容を復習したり、自分が感じた事を語り合い、理解と実感をより深めてゆくものです。
                <br />真理法則を1項目以上学修されましたら「親理総合理学修の理」にご参加ください。お申し込みは、
                <router-link
                    :to="{ name: 'CourseForm' }"
                    class="text-link-style"
                    >「お問い合わせ」フォーム</router-link
                >より受け付けております。
            </p>

            <h3 class="f16 f14-mb bold">【次の段階に進むには】</h3>
            <p class="f14 f12-mb">次の「神学科」の学修を希望される方は、</p>
            <ul class="disc-list-ul f14 f12-mb">
                <li class="disc-list-li">
                    「親理総合理学修の理」への真参加（3回以上）
                </li>
                <li class="disc-list-li">
                    「基本セミナー」の全6項目の受講（1項目につき3回）
                </li>
                <li class="disc-list-li">「生命自覚宣誓の理」</li>
            </ul>
            <p class="f14 f12-mb">
                を行って下さい。
                <br />「神学科」を受ける場合は、「自分自身の本質は物質的な肉体ではなく、永遠なる生命である」という「生命自覚宣誓証」を行うことが必要です。
                <br />
                <router-link
                    :to="{ name: 'CourseForm' }"
                    class="text-link-style"
                >「お問い合わせ」フォーム</router-link
                >より「生命自覚宣誓」の申し込みを行ってください。
            </p>
        </article>
    </div>
</template>
<script>
export default {
    name: 'BasicCourseVideos',
}
</script>
<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { watchCourse } from '@/service/api'
import { scrollInToView } from '@/utils/common.js'
import { ElMessageBox } from 'element-plus'
import { SuccessFilled } from '@element-plus/icons-vue'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
const store = useStore()
const router = useRouter()
const route = useRoute()
const serverRole = store.getters.getServerRole
onMounted(async () => {
    if (serverRole === 'admin') return

    if (route.hash) {
        scrollInToView(route.hash)
    }

    if (
        !store.getters.getDonateStatus['基本セミナー'] &&
        !store.getters.getDonateDialogStatus['基本セミナー']
    ) {
        if (!store.getters.getDonateDialogStatus['基本セミナー']) {
            store.commit('updateDonateDialogStatus', '基本セミナー')
        }
        setTimeout(() => {
            ElMessageBox.confirm(
                '「貴き親理のお授け」を<br/>真拝命拝霊拝受させて頂くにあたり、 <br/>『大親神大生命』に対し奉り <br/>「おつなぎ徳積みの理」を行わせて頂きましょう。',
                'おつなぎ徳積みの理',
                {
                    confirmButtonText: '今すぐ行わせて頂く',
                    cancelButtonText: 'あとで行う',
                    center: true,
                    dangerouslyUseHTMLString: true,
                }
            ).then(() => {
                cookies.set(
                    'backRoutePath',
                    '/member/dashboard/course/basic/videos',
                    60 * 5
                )
                cookies.set('donateCourse', '基本セミナー')
                router.push({ name: 'DonateProcessStripe' })
            })
        }, 1000)
    }
})

const videoList = store.getters.getBasicVideos
const isPC = store.getters.isPC
function pauseAll(e) {
    let video = document.querySelectorAll('video')
    for (let i = 0; i < video.length; i++) {
        if (video[i] === e.target) continue
        video[i].pause()
    }
}
</script>
<style lang="scss" scoped>
article {
    padding: 60px 0;
    h3 {
        margin: 2em 0 0.5em;
    }
}
</style>
